<template>
  <div class="meetinfo">
    <item-scroll ref="ItemScroll" v-loading="isloading">
      <div class="meetinfocontainer">
        <!-- <div class="meetinfo-courseware-container" v-if="isNeedSelect">
          <div class="meetinfo-courseware-title global_container_center">
            <div class="meetinfo-courseware-title-bg global_container_center">
              课堂讲题
            </div>
          </div>
          <div class="meetinfo-courseware-button-group">
            <div
              class="meetinfo-courseware-select meetinfo-courseware-admin-select global_primary_border_color global_primary_text_color"
              @click="selectCourseware(0)"
            >
              我帮专家选
            </div>
     
          </div>
        </div> -->
        <div class="meetinfo-main">
          <div class="meetrowtitle global_primary_text_color">会议信息</div>
          <!-- <div class="meetrowinput metrowinput_first" v-if="meetingInfo.id">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>会议名称
            </div>
            <div class="meetclumnvalue">
              <div class="meet-clunmu-input global_text_input">
                <el-input
                  placeholder="请输入会议名称"
                  v-model="meetingInfo.meeting_name"
                ></el-input>
              </div>
              <div class="inputtopblock" v-if="true"></div>
            </div>
          </div> -->
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>开始日期
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <el-date-picker
                v-model="meetTime.date"
                :editable="false"
                :clearable="false"
                type="date"
                @change="changeDate"
                value-format="YYYY/MM/DD"
                placeholder="请选择开始日期"
              >
              </el-date-picker>
              <div class="inputtopblock" v-if="showBlock()"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>开始时间
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <!-- <el-select v-model="meetTime.startTime" @change='changeStartTime()'  placeholder="开始时间" style='width:100%;text-align:center;'>
                            <el-option :label="item" :value="item" v-for='(item,i) in config.dateTimes.slice(0,-2)' :key='i'></el-option>
                        </el-select> -->
              <el-select
                v-model="meetTime.startHour"
                ref="startHour"
                @change="changeStartHour()"
                @focus="onHourFocus('startHour')"
                placeholder="时"
              >
                <el-option
                  v-for="item in config.startHours"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="meetTime.startMinute"
                ref="startMinute"
                @change="changeStartMinute()"
                @focus="onMinuteFocus('startMinute')"
                placeholder="分"
              >
                <el-option
                  v-for="item in config.startMinutes"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <div class="inputtopblock" v-if="showBlock()"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>结束时间
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <!-- <el-select v-model="meetTime.endTime"  @change='changeEndTime()'  placeholder="结束时间" style='width:100%;text-align:center;'>
                            <el-option :label="item" :value="item" v-for='(item,i) in config.dateTimes.slice(2)' :key='i'></el-option>
                        </el-select> -->
              <el-select
                v-model="meetTime.endHour"
                ref="endHour"
                @change="changeEndHour()"
                @focus="onHourFocus('endHour')"
                placeholder="时"
              >
                <el-option
                  v-for="item in config.endHours"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="meetTime.endMinute"
                ref="endMinute"
                @change="changeEndMinute()"
                @focus="onMinuteFocus('endMinute')"
                placeholder="分"
              >
                <el-option
                  v-for="item in config.endMinutes"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <div class="inputtopblock" v-if="showBlock()"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>互动专家
            </div>
            <div class="meetclumnvalue questionmeet global_text_select"
                :style="{ 
                  background: meetingInfo.id?(!showChangeButton?'#fff':'#ccc'):(status == 3?'#ccc' :'#fff'),
                }">
              <el-input
                class="input"
                placeholder="请输入专家姓名"
                v-model="doctorName"
                :disabled="meetingInfo.id ? showChangeButton : status == 3 ? true : false"
                @input="
                  doctorName = doctorName.replace(
                    /[^(\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF)•·]/g,
                    ''
                  )
                "
              ></el-input>
              <div
                class="meetinfo_button"
                @click="intelligentMatching"
                v-if="!meetingInfo.id && status != 3 || meetingInfo.id && !showChangeButton"
              >
                {{ matchNum ? "重新匹配" : "智能匹配" }}
              </div>
              <div class="meetinfo_button" v-if="meetingInfo.id&& meetingInfo.status == 0 && showChangeButton" @click="changeDoctor">
                变更
              </div>
            </div>
          </div>
          <p style="color: #f00;" v-if="doctorInfo.examine_status === 0">个人信息人工审核中，请等待</p>
          <p style="color: #f00;" v-if="doctorInfo.examine_status === 2">人工审核反馈：{{ doctorInfo.reason }}</p>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>会议讲题
            </div>
            <div class="meetclumnvalue questionmeet global_text_select" 
                :style="{
                  background: meetingInfo.courseware_id ? '#ccc' : '#fff',
                }">
              <!-- <el-select
                v-model="meetingInfo.courseware_id"
                placeholder="请选择会议讲题"
                style="width: 100%; text-align: center"
                v-show="meetingInfo.courseware_id === ''"
              >
                <el-option
                  :label="item.show_title"
                  :value="item.id"
                  v-for="(item, i) in config.courseware"
                  :key="i"
                  :class="{
                    meetinfo_show_bold_text:
                      item.id == meetingInfo.courseware_id,
                  }"
                ></el-option>
              </el-select> 
              <div
                class="courseware_title"
                v-show="meetingInfo.courseware_id !== ''"
              >
                {{ getCoursewareTitle }}
              </div>-->

              <el-input
                class="input"
                placeholder="请选择课件"
                v-model="meetingInfo.courseware_id"
                v-show="meetingInfo.courseware_id === ''"
                disabled
              ></el-input>
              <div
                class="courseware_title"
                v-show="meetingInfo.courseware_id !== ''"
              >
                {{ getCoursewareTitle }}
              </div>
              <div
                class="meetinfo_button"
                v-show="!meetingInfo.courseware_id"
                @click="selectCourseware(0)"
              >
                选择课件
              </div>
            </div>
          </div>

          <!-- <div> -->
          <div v-if="isShowDoc">
            <div class="meetinfo_remind" v-if="!isChangeDoc">提醒:</div>
            <div class="meetinfo_remind" v-if="!isChangeDoc">
              每场新建会议仅可使用2次智能匹配，匹配后无需再完善专家已有信息，请慎重选择！
            </div>
            <div class="meetinfo_mate_doctor">
              <item-scroll ref="ItemScrollDoctor">
                <div style="">
                  <div
                    class="meetinfo_mate_doctor_item"
                    v-for="(item, i) in config.AllDoctor"
                    :key="i"
                    @click="selectExpert(item)"
                  >
                    {{ item.name }}-{{ item.hospital }}
                  </div>
                  <div
                    class="meetinfo_mate_doctor_item"
                    @click="selectExpert()"
                  >
                    {{config.AllDoctor.length>0 ? "以上都不是，我要新增专家":"未匹配到该专家，请点击本提示自行新增专家"}}
                  </div>
                </div>
              </item-scroll>
            </div>
          </div>

          <!-- <div class="meetrowtitle global_primary_text_color">主持专家信息</div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>所在省份
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <el-input
                placeholder="请选择省份"
                v-model="doctorInfo.province"
              ></el-input>
              <div class="inputtopblock"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>所在城市
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <el-input
                placeholder="请选择城市"
                v-model="doctorInfo.city"
              ></el-input>
              <div class="inputtopblock"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>所在医院
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <el-input
                placeholder="请选择城市"
                v-model="doctorInfo.hospital"
              ></el-input>
              <div class="inputtopblock"></div>
            </div>
          </div>
          <div class="meetrowinput">
            <div class="meetrowkey">
              <span class="meetrowkey_mark">*</span>姓名
            </div>
            <div class="meetclumnvalue global_text_input global_text_select">
              <el-input
                placeholder="请输入姓名"
                v-model="doctorInfo.name"
              ></el-input>
              <div class="inputtopblock"></div>
            </div>
          </div> -->

          <div v-if="!meetingInfo.id && (status == 2 || status == 3)">
            <div class="meetoperabtn">
              <el-button type="primary" @click="doCommit()" round>
                <img :src="require('@/assets/images/meetopera.png')" alt="" />
                <span>提交</span>
              </el-button>
            </div>
          </div>
          <!-- 有会议ID，且 -->
          <div class="meetalterbtn" v-if="meetingInfo.id && !showBlock()">
            <el-button type="primary" @click="doCommit()" round>
              <img :src="require('@/assets/images/meetopera.png')" alt="" />
              <span>确认修改</span>
            </el-button>
            <!-- <span class="cancelmeet" @click="cancelMeet()">取消会议</span> -->
          </div>
          <!-- 会议状态为2时：不是超时自动结束，同时也不是审核失败才显示返回 -->
          <div class="meetalterbtn" v-if="meetingInfo.status == 2 && !meetingInfo.auto_endmeet && meetingInfo.audit_status!=30">
            <el-button type="primary" @click="goBack()" round>
              <!-- <img :src="require('@/assets/images/meetopera.png')" alt="" /> -->
              <span>返回</span>
            </el-button>
            <!-- <span class="cancelmeet" @click="cancelMeet()">取消会议</span> -->
          </div>
        </div>

        <!-- 专家信息 -->
        <!-- <div class="meetinfo_doctorinfo" v-if="isShowDoctorInfo && status != 3">
          <div
            class="meetinfo_doctorinfo_title global_primary_text_color meetrowtitle_pd"
          >
            专家信息
          </div>
          <div
            class="meetinfo_doctorinfo_title meetrowtitle_pd"
            style="background: #cccccc"
          >
            {{ doctorInfo.name }}
            <span v-if="doctorInfo.hospital">-</span>
            {{ doctorInfo.hospital }}
          </div>
          <div class="meetrowtitle_pd biographical_notes">
            <div class="meetinfo_doctorinfo_key">
              <span class="meetrowkey_mark">*</span
              ><span
                >简历图片（请确保是专家近期照片，如不是，请重新上传）
              </span>
            </div>
            <img
              :src="require('@/assets/images/icon/resume_img_have.png')"
              class="meet_cover"
              v-if="!doctorInfo.professional_cover"
              @click="selectCover"
            />
            <img
              v-else
              @click="selectCover"
              :src="doctorInfo.professional_cover"
              class="meet_cover"
            />
            <div style="display: flex; justify-content: flex-end">
              <div
                class="meetinfo_button"
                v-if="doctorInfo.professional_cover"
                @click="doNext"
              >
                下一页
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </item-scroll>

    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="config.cropper.cropWidth"
      :CropHeight="config.cropper.cropHeight"
      :flexScale="config.cropper.flexScale"
      :MaxSize="config.cropper.MaxSize"
      :isShowHeadimgCalibration="config.cropper.isShowHeadimgCalibration"
      v-if="cropperManager.resetFile"
    ></image-cropper>
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFile"
      ref="selectfiles"
      accept="image/*"
    />
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import ImageCropper from "@/components/unit/ImageCropper";
import upload from "@/common/uploads";
export default {
  name: "meetinfo",
  data: () => {
    return {
      cropperManager: {
        resetFile: false,
      },
      config: {
        cropper: {
          cropWidth: 250,
          cropHeight: 250,
          flexScale: [1, 1],
          MaxSize:2,
          isShowHeadimgCalibration: false,
        },
        dateTimes: [],
        courseware: [],
        startHours: [],
        endHours: [],
        startMinutes: [],
        endMinutes: [],
        AllDoctor: [
          { id: 14222, name: "黄相菊", hospital: "广州医科大学附属第一医院" },
          { id: 22414, name: "黄相菊", hospital: "四川大学华西医院" },
          { id: 24428, name: "黄相菊", hospital: "广州医科大学附属第一医院" },
          { id: 24818, name: "黄相菊", hospital: "广州医科大学附属第一医院" },
        ],
      },
      isloading: false,
      isSelectDoc:false,//是否选择了医生，防止增加多次匹配医生次数
      isChangeDoc:false,//是否正在变更医生(变更医生时智能匹配不在提示次数)
      showChangeButton:false,
      updateDoctorInfo: {},//修改到一半缓存的医生信息
      doctorInfo: {
        // id: "",
        // cover: "",
        // name: "",
        // province: "",
        // city: "",
        // hospital: "",
        // professional_cover: "",
        // photo: "",
        // description: "",
      },
      catchMeetTime:{
        start_time: "",
        end_hour: ""
      },
      meetingInfo: {
        doctor_id: "",
        meeting_name: "",
        start_time: "",
        end_hour: "",
        courseware_id: "",
        photo: "",
        description: "",
        status: 0,
        watch:false
      },
      meetTime: {
        date: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
        startTime: "",
        endTime: "",
      },
      cacheTime: {
        date: "",
        startTime: "",
        endTime: "",
      },
      isNeedSelect: 1, // 是否需要选择课件
      meetId:"",
      resetFile: true,
      isShowDoc: false,
      isShowDoctorInfo: false,
      status: "",
      matchNum: 0,
      doctorName: "",
    };
  },
  watch: {
    doctorInfo: {
      handler(newValue, oldValue) {
        this.$tools.setLocalCookie("doctorInfo", newValue);
        // 处理值变化
        this.doctorName = this.$tools.getLocalCookie("doctorInfo").name;
      },
      immediate: false,
      deep: true,
    },
  },

  created() {
    this.config.dateTimes = this.$tools.getDateConfig();
    this.meetId = this.$route.query.meetId;
    this.meetingInfo.doctor_id = this.$route.query.doctorId || 0;
    this.status = this.$tools.getLocalCookie("status");
    // this.matchNum = this.$tools.getLocalCookie("matchNum");
    this.setDocumentTitle();
    let doctorInfo = this.$tools.getLocalCookie("doctorInfo");
    this.updateDoctorInfo = this.$tools.getLocalCookie("updateDoctorInfo") || null;
    if (doctorInfo?.name) {
      this.doctorInfo = Object.assign(this.doctorInfo, doctorInfo);
    }

    if (this.$route.query.allDoctorid) {
      this.doctorInfo.all_doctor_id = this.status == 2?this.doctorInfo.all_doctor_id:this.$route.query.allDoctorid;
    }
    this.loadData();
    setTimeout(() => {
      this.$refs.ItemScroll.initScroll();
    }, 500);
    this.isShowDoctorInfo = this.doctorInfo.id;
  },
  computed: {
    getCoursewareTitle() {
      // console.log(
      //   "this.meetingInfo.courseware_id :>> ",
      //   this.meetingInfo.courseware_id
      // );
      return this.meetingInfo.courseware_id !== ""
        ? this.config.courseware.find(
            (x) => x.id == this.meetingInfo.courseware_id
          )?.show_title || ""
        : "";
    },
  },
  methods: {
    receiveData(data) {
      if(!data.url) return;
      this.doctorInfo.professional_cover = data.url;
      this.cropperManager.resetFile = false;
    },
    selectCover() {
      if (!this.resetFile) return;
      this.$refs.selectfiles.click();
    },
    async changeFile(e) {
      let url = await upload.uploadFile(e.target.files[0]);
      this.resetFile = false;
      this.$nextTick(() => {
        this.resetFile = true;
        this.cropperManager.resetFile = true;
        this.$nextTick(() => {
          this.$refs.imageCropper.loadUrl(url);
        });
      });
    },

    setDocumentTitle() {
      let query = this.$route.query;
      let title = "";
      if (this.meetingInfo.status !== 0 && !this.meetingInfo.auto_endmeet || query.watch) title = "会议查看";
      else {
        this.showChangeButton = query.meetId ? true : false;;
        title = query.meetId ? "互动课堂修改" : "互动课堂申请";
      }
      document.getElementsByTagName("title")[0].innerHTML = title;
    },
    loadData() {
      if (this.$route.query.meetId) {
        this.loadMeet();
      } else {
        this.loadStoreMeetingInfo();
      }
      if (this.status != 2&&this.$route.query.allDoctorid) {
        this.loadDcotroInfoByAllID(this.doctorInfo.all_doctor_id);
      }
      this.loadCourseware();
    },
    loadCourseware() {
      let url = this.$tools.getURL(
        this.$urls.admin.courseware,
        {doctor_id:this.$route.query.doctorId||this.doctorInfo.id}
      );
      this.$axios.get(url).then((res) => {
        this.config.courseware = res.data;
        this.config.courseware.push({
          id: 0,
          show_title: "待选择",
        });
      });
    },
    loadStoreMeetingInfo() {
      let meetingInfo = this.$tools.getLocalCookie("meetingInfo");
      if (meetingInfo) {
        this.meetingInfo = { ...meetingInfo };
        this.$tools.setLocalCookie("meetingInfo", "");
        this.initMeet();
      }
    },
    initMeet() {
      let date = this.meetingInfo.start_time || this.meetingInfo.end_time;
      if (!date) return;
      this.meetTime.date = this.$tools.getDate(date, "yyyy-MM-dd");
      if (this.meetingInfo.start_time) {
        this.meetTime.startTime = this.$tools.getDate(
          this.meetingInfo.start_time,
          "hh:mm"
        );
        this.meetTime.startHour = this.meetTime.startTime.split(":")[0];
        this.meetTime.startMinute = this.meetTime.startTime.split(":")[1];
      }
      if (this.meetingInfo.end_time) {
        this.meetTime.endTime = this.$tools.getDate(
          this.meetingInfo.end_time,
          "hh:mm"
        );
        this.meetTime.endHour = this.meetTime.endTime.split(":")[0];
        this.meetTime.endMinute = this.meetTime.endTime.split(":")[1];
      }
      this.cacheTime = { ...this.meetTime };
    },
    //一下情况不允许修改会议：有会议ID，且状态不为0且不是超时结束且不是审核失败，或者是查看会议
    showBlock() {
      return (this.meetId && this.meetingInfo.status !== 0 &&!this.meetingInfo.auto_endmeet && this.meetingInfo.audit_status != 30) || this.meetingInfo.watch;
    },

    changeDate() {
      console.log("this.meetTime :>> ", this.meetTime);
      if (
        this.$tools.getTime(this.meetTime.date) <
        this.$tools.getTime(this.$tools.getDate())
      ) {
        this.$tips.error({ text: "会议日期不能早于今日" });
        this.meetTime.date = "";
      }
      this.meetTime.startHour = "";
      this.meetTime.startMinute = "";
      this.meetTime.startTime = "";
      this.meetTime.endHour = "";
      this.meetTime.endMinute = "";
      this.meetTime.endTime = "";
      this.cacheTime = { ...this.meetTime };
    },
    loadMeet() {
      let url = this.$tools.getURL(this.$urls.admin.meeting, {
        meeting_id: this.$route.query.meetId,
      });
      this.$axios.get(url).then((res) => {
        this.meetingInfo = { ...this.meetingInfo, ...res.data };
        this.catchMeetTime.start_time = res.data.start_time;
        this.catchMeetTime.end_time = res.data.end_time;
        this.isNeedSelect = 0;
        this.setDocumentTitle();
        this.initMeet();
        this.loadStoreMeetingInfo();
        this.meetingInfo.update_doctor_number = res.data.update_doctor_number;
        this.meetingInfo.update_time_number = res.data.update_time_number;
      });
    },
    selectCourseware(type) {
      if (!this.meetTime.date||!this.meetTime.startTime||!this.meetTime.endTime) {
        this.$tips.error({ text: "请先选择会议日期和时间" });
        return false;
      }
      //点击医生进入新建会议（status为3),没有医生ID时，提示匹配专家
      if (this.status == 3&&!this.doctorInfo?.id && !this.doctorInfo?.all_doctor_id) {
        this.$tips.error({ text: "请先匹配专家" });
        return false;
      }
      //点击开始匹配会议(status为1),没有在医生详情页提交过医生(提交后status为2)，提示匹配专家
      if (this.status == 1) {
        this.$tips.error({ text: "请先匹配专家" });
        return false;
      }
      this.isNeedSelect = type;
      if (!this.isNeedSelect) {
        this.formatData();
        this.$tools.setLocalCookie("meetingInfo", this.meetingInfo);
        this.$router.push({
          path: "/coursewares",
          query: {
            type: 0,
            ...this.$route.query,
            doctorId: this.doctorInfo.id,
          },
        });
      } else {
        this.meetingInfo.courseware_id = 0;
      }
    },
    formatData() {
      if (this.meetTime.startTime)
        this.meetingInfo.start_time = `${this.meetTime.date} ${this.meetTime.startTime}:00`;
      if (this.meetTime.endTime)
        this.meetingInfo.end_time = `${this.meetTime.date} ${this.meetTime.endTime}:00`;
    },
    checkData() {
      // if(this.meetingInfo.meeting_name == '') {
      //     this.$tips.error({text:'会议名称不能为空'})
      //     return false;
      // }
      if (this.meetingInfo.start_time == "") {
        this.$tips.error({ text: "会议开始时间不能为空" });
        return false;
      }
      if (!this.meetingInfo.id) {
        if (
          this.$tools.getTime(this.meetingInfo.start_time) <
          this.$tools.getTime() + 15 * 60 * 1000 - 59 * 1000
        ) {
          this.$tips.error({ text: "会议开始时间必须在当前时间15分钟之后" });
          return false;
        }
      }
      if (this.meetingInfo.courseware_id === "") {
        this.$tips.error({ text: "会议讲题不能为空" });
        return false;
      }
      return true;
    },
    //获取智能匹配次数
    async matchTimes(){
      let url = this.$urls.admin.matchTimes;
      let data = await this.$axios.get(url);
      // if(data.errorCode) return -1;
      return data.mate_doctor
    },
    //智能匹配
    async intelligentMatching() {
      if (!this.meetTime.date||!this.meetTime.startTime||!this.meetTime.endTime) {
        this.$tips.error({ text: "请先选择会议日期和时间" });
        return false;
      }
      if (this.doctorName.length < 2) {
        this.$tips.error({ text: "请输入专家完整名字" });
        return false;
      }
      this.loadMatchDoctor();
      // if(!this.isChangeDoc){
      //   this.MatchDialog();
      // }
    },
    //获取智能匹配的专家
    loadMatchDoctor(){
      let url = this.$tools.getURL(this.$urls.doctor.getAllDoctor, {
        doctor_name: this.doctorName,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.AllDoctor = res.data;
          this.isShowDoc = res.type == "ok" ? "true" : "false";
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
            this.isShowDoctorInfo = false;
            // this.matchNum = Number(this.matchNum) + 1;
            // this.$tools.setLocalCookie("matchNum", this.matchNum);
          }, 500);
        }).catch((err) => {
          if (err.msg == "匹配医生次数已达上限" && err.type == "error") {
            this.isShowDoc = true;
          }
        });
    },
    //选择专家(根据智能匹配或者变更专家弹出不同的框)
    async selectExpert(item) {
        if(this.isSelectDoc) return;
        this.isSelectDoc = true;
        if(item&&item.meeting_count >= 4){
          this.$tips.error({ text: "医生会议数量已达上限" });
          setTimeout(()=>{
            this.isSelectDoc = false;
          },1000)
          return
        }
        this.doctorInfo = {};
        this.doctorInfo.name = this.doctorName;
        if (item) {
          if(item.id){
            await this.loadDcotroInfoByAllID(item.id);
          }else{
            await this.loadDcotroInfoByID(item.doctor_id);
          }
          if(!this.isChangeDoc){
            //和修改缓存的医生是同一个
            if(this.updateDoctorInfo!=null && 
            this.updateDoctorInfo.name == this.doctorInfo.name && 
            (this.updateDoctorInfo.all_doctor_id == this.doctorInfo.all_doctor_id)){
              this.doctorInfo = this.updateDoctorInfo;
              this.isShowDoctorInfo = true;
              this.isShowDoc = false;
              this.isSelectDoc = false;
              this.doNext()
            }else{
              this.MatchDialog();
            }
          }else{
            this.changeDocDialog();
          }
        }else{
          if(this.isChangeDoc){
              this.changeDocDialog();
          }else{
            this.isShowDoctorInfo = true;
            this.isShowDoc = false;
            this.isSelectDoc = false;
            this.doNext()
          }
        }
      // setTimeout(() => {
      //   this.$refs.ItemScroll.initScroll();
      // }, 500);
    },
    
    //智能匹配的弹框提示
    async MatchDialog(){
        this.matchNum = await this.matchTimes();
        let text = `智能匹配只有2次机会，您已匹配${this.matchNum}次，还可匹配${2-this.matchNum}次`
        let option = {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              center: true,
              type: "warning",
              customClass: "meetinfo_confirm_customClass",
            }
        if (this.matchNum >= 2) {
          // this.$tips.error({ text: "匹配已达上显" });
          this.$confirm(`${text}，再次匹配会议会冻结,请谨慎操作`,"提示",option).then(() => {
              this.discardMeet();
            }).catch(() => {
              this.isSelectDoc = false;
            });
          return false;
        }else{
          this.$confirm( `${text}，请谨慎操作`, "提示", option).then(async ()=>{
            await this.addMatchTimes();
            this.isShowDoc = false;
            this.isSelectDoc = false;
            this.doNext()
            // this.loadMatchDoctor();
          }).catch(() => {
            this.isSelectDoc = false;
          });
        }
    },
    //变更专家的弹框
    changeDocDialog(){
      let text = `本次会议只可变更1次专家，您已变更匹配${this.meetingInfo.update_doctor_number}次，还可变更匹配${1-this.meetingInfo.update_doctor_number}次`
      let option = {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              center: true,
              type: "warning",
              customClass: "meetinfo_confirm_customClass",
          }
      if(this.meetingInfo.update_doctor_number>=1){
        this.$confirm(`${text}。点击确认会议会冻结，请谨慎操作`,"提示",option).then(()=>{
          //变更医生超过1次，冻结会议
          this.freezeMeeting(1);
          }).catch(() => {
            this.isSelectDoc = false;
          });
      }else{
        this.$confirm(`${text}。请谨慎操作`,"提示",option).then(async ()=>{
            await this.addChangeDocTimes();
            this.isShowDoc = false;
            this.isSelectDoc = false;
            this.doNext()
          }).catch(() => {
            this.isSelectDoc = false;
          });
      }
    },
    //变更时间弹框
    changeMeetTimeDialog(callback){
      let text = `本次会议只可变更5次时间，您已变更${this.meetingInfo.update_time_number}次，还可变更${5-this.meetingInfo.update_time_number}次`
      let option = {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              center: true,
              type: "warning",
              customClass: "meetinfo_confirm_customClass",
          }
      if(this.meetingInfo.update_time_number>=5){
        this.$confirm(`${text}。点击确认会议会冻结，请谨慎操作`,"提示",option).then(()=>{
          //变更时间超过5次，冻结会议
          this.freezeMeeting(2);
          }).catch(() => {});
      }else{
        this.$confirm(`${text}。请谨慎操作`,"提示",option).then(()=>{
          callback&&callback();
          }).catch(() => {});
      }
    },
    //增加一次匹配次数
    async addMatchTimes(){
      let url = this.$tools.getURL(this.$urls.admin.addMatchTimes,{all_doctor_id:this.doctorInfo.all_doctor_id});
      await this.$axios.get(url);
    },
    //增加一次变更医生次数
    async addChangeDocTimes(){
      let url = this.$tools.getURL(this.$urls.admin.addChangeDocTimes,{id:this.$route.query.meetId});
      await this.$axios.get(url);
    },
    //变更医生或变更时间达到上限冻结会议
    async freezeMeeting(freeze_reason){
      let url = this.$tools.getURL(this.$urls.admin.freezeMeeting,{id:this.$route.query.meetId,freeze_reason:freeze_reason});
      await this.$axios.get(url);
      this.discardDialog();
    },
    //智能匹配达到上限冻结会议
    discardMeet(){
      this.formatData();
      let data = {
        doctor_name:this.doctorName,
        start_time:this.meetingInfo.start_time,
        end_time:this.meetingInfo.end_time
      }
      this.$axios.post(this.$urls.doctor.discardMeet,data).then((res) => {
        this.$tools.setLocalCookie("status", 0);
        // this.$tools.setLocalCookie("matchNum", 0);
        this.$tools.setLocalCookie("doctorInfo", {});
        this.$tools.setLocalCookie("meetingInfo", {});
        this.discardDialog();
      });
    },
    discardDialog(){
        this.$confirm("本次会议已冻结，请前往主页申请解冻","提示",{
            confirmButtonText: "确定",
            showCancelButton:false,
            center: true,
            type: "warning",
            customClass: "meetinfo_confirm_customClass",
        }).then(()=>{
          this.$tools.setLocalCookie("updateDoctorInfo", "");
          this.$router.push({
            path: "/adminmeet",
          });
        }).catch(() => {
          this.$router.push({
            path: "/adminmeet",
          });
        });
    },
    //根据doctor_id查医生
    loadDcotroInfoByID(id){
      return new Promise((resolve,reject)=>{
        let url = this.$tools.getURL(this.$urls.admin.doctor, {
          id: id
        });
        this.$axios.get(url).then((res) => {
            this.doctorInfo = res.data;
            this.getApplyAuditStatus();
            resolve(res.data);
          // this.doctorInfo.professional_cover = res.data[0].profile;
          // this.doctorInfo.job_name = res.data[0].graded_title;
          // this.doctorInfo.bank_name = res.data[0].bank;
        }).catch(err=>{
          reject(err)
        });
      })
    },
    //根据all_doctor_id查医生
    loadDcotroInfoByAllID(id) {
      return new Promise((resolve,reject)=>{
        let url = this.$tools.getURL(this.$urls.doctor.getAllDoctorInfo, {
          id: id,
        });
        this.$axios.get(url).then((res) => {
          if(res.data.length>0){
            this.doctorInfo = res.data[0];
            this.doctorInfo.all_doctor_id = res.data[0]?.all_doctor_id;
            this.getApplyAuditStatus();
            resolve(res.data[0]);
          }
          // this.doctorInfo.professional_cover = res.data[0].profile;
          // this.doctorInfo.job_name = res.data[0].graded_title;
          // this.doctorInfo.bank_name = res.data[0].bank;
        }).catch(err=>{
          reject(err)
        });
      })
    },
    //获取人工审核状态
    getApplyAuditStatus(){
      let url = this.$tools.getURL(this.$urls.admin.examineStatus,{doctor_id:this.doctorInfo.id || "",all_doctor_id:this.doctorInfo.all_doctor_id});
      this.$axios.get(url).then(res=>{
        this.doctorInfo.examine_status = res.examine_status;
        this.doctorInfo.reason = res.reason;
        // this.$refs.itemScroll.initScroll();
      }).catch(err=>{
        console.log(err)
      })
    },
    doNext() {
      this.isShowDoctorInfo = false;
      this.formatData();
      this.$tools.setLocalCookie("meetingInfo", this.meetingInfo);
      this.$tools.setLocalCookie("status", 1);
      if (this.doctorInfo?.all_doctor_id || this.doctorInfo?.id) {
        this.$router.push({
          path: "/docterdetail",
          query: {
            doctorId: this.doctorInfo.all_doctor_id || this.doctorInfo?.id,
          },
        });
      } else {
        this.$router.push({
          path: "/createdocter",
          query: {
            meetId:this.$route.query.meetId,
          }
        });
      }
    },
    goBack(){
      this.$router.back();
    },
    doCommit() {
      this.meetingInfo.doctor_id = this.doctorInfo.id;
      this.formatData();
      if (!this.checkData() || this.isloading) return;
      if (!this.meetingInfo.id && this.meetingInfo.courseware_id !== 0) {
        // this.$confirm("提交后会议讲题不能更改，请确认是否提交?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        //   customClass: "meetinfo_confirm_customClass",
        // })
        //   .then(() => {
            this.meetingInfo.id ? this.checkUpdateMeetTime() : this.createMeet();
            this.$tools.setLocalCookie("status", 0);
          // })
          // .catch(() => {});
      } else {
        this.meetingInfo.id ? this.checkUpdateMeetTime() : this.createMeet();
      }
    },
    //检查是否变更时间
    checkUpdateMeetTime(){
      if(this.catchMeetTime.start_time && this.catchMeetTime.end_time && 
        (this.catchMeetTime.start_time!=this.meetingInfo.start_time ||this.catchMeetTime.end_time!=this.meetingInfo.end_time)
      ){
        this.changeMeetTimeDialog(this.updateMeet)
      }else{
        this.updateMeet();
      }
    },
    updateMeet() {
      this.isloading = true;
      this.$axios
        .patch(this.$urls.admin.meeting, this.meetingInfo)
        .then((res) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
          localStorage.removeItem("doctorInfo");
          this.$tools.setLocalCookie("updateDoctorInfo", "");
          if (this.status == 2) {
            this.$router.push({
              path: "/adminmeet",
            });
          } else {
            this.$router.push({
              path: "/doctermeet",
              query: {
                doctorId: this.$route.query.doctorId,
              },
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    cancelMeet() {
      if (this.isloading) return;
      this.isloading = true;
      let url = this.$tools.getURL(this.$urls.admin.meeting, {
        id: this.meetingInfo.id,
      });
      this.$axios
        .delete(url)
        .then((res) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
          this.$tips.success({ text: "取消会议成功" });
          setTimeout(() => {
            this.$router.push({
              path: "/doctermeet",
              query: {
                doctorId: this.$route.query.doctorId,
              },
            });
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    createMeet() {
      this.isloading = true;
      this.$axios
        .post(this.$urls.admin.meeting, {
          ...this.meetingInfo,
          id: this.doctorInfo.id,
        })
        .then((res) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
          localStorage.removeItem("doctorInfo");
          this.$tools.setLocalCookie("updateDoctorInfo", "");
          if (this.status == 2) {
            this.$router.push({
              path: "/adminmeet",
            });
          } else {
            this.$router.push({
              path: "/doctermeet",
              query: {
                doctorId: this.$route.query.doctorId,
              },
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },

    selectFile() {
      this.$refs.awaterimg.click();
    },
    onHourFocus(key) {
      if (key == "startHour") {
        if (!this.meetTime.date) {
          this.$tips.error({ text: "请先选择开始日期" });
          this.meetTime[key] = "";
          return;
        }
        if (
          this.$tools.getTime(this.meetTime.date) >
          this.$tools.getTime(this.$tools.getDate())
        ) {
          this.config.startHours = [
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
          ];
        } else {
          this.config.startHours = [];
          let hour = new Date().getHours();
          let minute = new Date().getMinutes();
          hour = minute + 15 > 55 ? hour + 1 : hour; //会议要在当前时间15分钟之后，若15分钟之后大于55，则当前小时不能选
          for (let curHour = hour; curHour <= 20; curHour++) {
            let hourText = String(curHour).padStart(2, "0");
            this.config.startHours.push(hourText);
          }
        }
      }
      if (key == "endHour") {
        if (!this.meetTime.startHour || !this.meetTime.startMinute) {
          this.$tips.error({ text: "请先选择开始时间" });
          this.meetTime[key] = "";
          return;
        }
        let index =
          Number(this.meetTime.startMinute) + 25 > 55
            ? this.config.startHours.indexOf(this.meetTime.startHour) + 1
            : 0;
        let tempEndHours = JSON.parse(JSON.stringify(this.config.startHours));
        tempEndHours.push("21");
        this.config.endHours = tempEndHours
          .slice(index)
          .filter((x) => x >= this.meetTime.startHour);
      }
    },
    onMinuteFocus(key) {
      if (key == "startMinute") {
        if (!this.meetTime.startHour) {
          this.$tips.error({ text: "请先选择开始时间的小时" });
          this.meetTime[key] = "";
          this.config.startMinutes = [];
          return;
        }
        this.config.startMinutes = [];
        let maxMinute = this.meetTime.startHour == 20 ? 35 : 55; //开始时间未20点时，分钟最大为35(结束时间预留25分钟)
        let initMinute = 0;
        //会议开始日期小于等于今天
        if( !(this.$tools.getTime(this.meetTime.date) > this.$tools.getTime(this.$tools.getDate()))){
          let hour = new Date().getHours();
          let minute = new Date().getMinutes();
          //开始时间小时等于当前时间小时,可选的分钟往后加15(只有当前小时离55分的时差大于15分钟才能选当前小时)
          initMinute = hour == this.meetTime.startHour ? minute + 15 : (minute > 45 ? (minute + 15) % 60 : 0);
          // initMinute = hour == this.meetTime.startHour
        }
        // let initMinute =
        //   hour == this.meetTime.startHour
        //     ? minute + 15
        //     : minute > 45
        //     ? (minute + 15) % 60
        //     : 0;
        for (let curMinute = initMinute; curMinute <= maxMinute; curMinute++) {
          if (curMinute % 5 == 0) {
            let minuteText = String(curMinute).padStart(2, "0");
            this.config.startMinutes.push(minuteText);
          }
        }
      }
      if (key == "endMinute") {
        if (!this.meetTime.endHour) {
          this.$tips.error({ text: "请先选择结束时间的小时" });
          this.meetTime[key] = "";
          this.config.endMinutes = [];
          return;
        }
        this.config.endMinutes = [];
        let maxMinute = this.meetTime.endHour == 21 ? 0 : 55;
        let startTime = this.$tools.getTime(
          `${this.meetTime.date} ${this.meetTime.startTime}:00`
        );
        for (let curMinute = 0; curMinute <= maxMinute; curMinute++) {
          let minuteText = String(curMinute).padStart(2, "0");
          let endTime = this.$tools.getTime(
            `${this.meetTime.date} ${this.meetTime.endHour}:${minuteText}:00`
          );
          if (curMinute % 5 == 0 && endTime >= startTime + 25 * 60 * 1000) {
            this.config.endMinutes.push(minuteText);
          }
        }
      }
    },
    changeStartHour() {
      if (this.meetTime.startMinute) {
        this.meetTime.startTime = `${this.meetTime.startHour}:${this.meetTime.startMinute}`;
        this.changeStartTime();
      }
    },
    changeStartMinute() {
      if (!this.meetTime.startHour) {
        this.$tips.error({ text: "请先选择开始时间小时" });
        this.meetTime.startMinute = "";
        return;
      }
      this.meetTime.startTime = `${this.meetTime.startHour}:${this.meetTime.startMinute}`;
      this.changeStartTime();
    },
    changeStartTime() {
      let startTime = this.$tools.getTime(
        `${this.meetTime.date} ${this.meetTime.startTime}:00`
      );
      if (startTime < Date.now() + 15 * 60 * 1000 - 59 * 1000) {
        this.$tips.error({ text: "会议开始时间必须在当前时间15分钟之后" });
        this.meetTime.startHour = "";
        this.meetTime.startMinute = "";
        // this.meetTime = {...this.cacheTime}
        return;
      }
      this.meetTime.endTime = this.$tools.getDate(
        startTime + 25 * 60 * 1000,
        "hh:mm"
      );
      this.meetTime.endHour = this.meetTime.endTime.split(":")[0];
      this.meetTime.endMinute = this.meetTime.endTime.split(":")[1];
      this.cacheTime = { ...this.meetTime };
      // if (!this.meetTime.endTime) {
      //   this.meetTime.endTime = this.$tools.getDate(
      //     startTime + 25 * 60 * 1000,
      //     "hh:mm"
      //   );
      //   this.meetTime.endHour = this.meetTime.endTime.split(":")[0];
      //   this.meetTime.endMinute = this.meetTime.endTime.split(":")[1];
      //   this.cacheTime = { ...this.meetTime };
      // } else {
      //   let endTime = this.$tools.getTime(
      //     `${this.meetTime.date} ${this.meetTime.endTime}:00`
      //   );
      //   if (startTime + 25 * 60 * 1000 > endTime) {
      //     this.meetTime.endTime = this.$tools.getDate(
      //       startTime + 25 * 60 * 1000,
      //       "hh:mm"
      //     );
      //     this.meetTime.endHour = this.meetTime.endTime.split(":")[0];
      //     this.meetTime.endMinute = this.meetTime.endTime.split(":")[1];
      //     this.cacheTime = { ...this.meetTime };
      //   }
      // }
    },
    changeEndHour() {
      if(this.meetTime.endHour == "21"){
        this.meetTime.endMinute = "00";
      } 
      if (this.meetTime.endMinute) {
        this.meetTime.endTime = `${this.meetTime.endHour}:${this.meetTime.endMinute}`;
        this.changeEndTime();
      }
    },
    changeEndMinute() {
      if (!this.meetTime.endHour) {
        this.$tips.error({ text: "请先选择结束时间小时" });
        this.meetTime.endMinute = "";
        return;
      }
      this.meetTime.endTime = `${this.meetTime.endHour}:${this.meetTime.endMinute}`;
      this.changeEndTime();
    },
    changeEndTime() {
      let endTime = this.$tools.getTime(
        `${this.meetTime.date} ${this.meetTime.endTime}:00`
      );
      if (!this.meetTime.startTime) {
        this.meetTime.startTime = this.$tools.getDate(
          endTime - 25 * 60 * 1000,
          "hh:mm"
        );
        this.meetTime.startHour = this.meetTime.startTime.split(":")[0];
        this.meetTime.startMinute = this.meetTime.startTime.split(":")[1];
        this.cacheTime = { ...this.meetTime };
      } else {
        let startTime = this.$tools.getTime(
          `${this.meetTime.date} ${this.meetTime.startTime}:00`
        );
        if (startTime + 25 * 60 * 1000 > endTime) {
          this.$tips.error({ text: "会议结束时间必须在开始时间25分钟之后" });
          this.meetTime.endTime = this.$tools.getDate(
            startTime + 25 * 60 * 1000,
            "hh:mm"
          );
          this.meetTime.endHour = this.meetTime.endTime.split(":")[0];
          this.meetTime.endMinute = this.meetTime.endTime.split(":")[1];
        }
      }
    },
    changeDoctor(){
      this.showChangeButton = false;
      this.isChangeDoc = true;
    }
  },
  components: {
    ItemScroll,
    ImageCropper,
  },
  // unmounted() {
  //   this.$tools.setLocalCookie("matchNum", 0); //离开页面  销毁 匹配次数
  // },
};
</script>
<style>
.meetinfo {
  padding: 2.4vw 4vw 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f2f2f2;
}

.meetinfo-main {
  background: #fff;
  padding: 0 2vw;
}
.meetinfo-courseware-container {
  /* height:20vw; */
  background: #fff;
  padding: 0 2vw 4vw;
  margin-bottom: 4vw;
}
.meetinfo-courseware-button-group {
  display: flex;
  justify-content: center;
}
.meetinfo-courseware-select {
  padding: 2vw 4vw;
  border-width: 1px;
  border-style: solid;
  border-radius: 999vw;
  margin-top: 4vw;
}
.meetinfo-courseware-admin-select {
  background-color: #008f3d;
  color: #fff;
}
.meetinfo-courseware-select:first-child {
  /* margin-right:4vw; */
}
.meetinfo-courseware-title {
  color: #fff;
  width: 100%;
  height: 10vw;
  text-align: center;
  border-radius: 999vw;
  padding: 1vw 0;
}
.meetinfo-courseware-title-bg {
  height: 100%;
  width: 32vw;
  background: url("../../../assets/images/tip-bg.png");
  background-size: 100% 100%;
  color: #fff;
}
.meetinfo .el-date-editor .el-input__prefix {
  display: none;
  width: 0;
}
.meetinfo .el-date-editor .el-input__inner {
  padding-left: 0;
}
.time-select-item {
  font-size: 3vw !important;
  font-weight: 400;
  line-height: 4.8vw !important;
}
.meetinfo .meetinfocontainer {
  height: 100%;
  overflow: hidden;
}
.meetinfo .el-input.is-disabled .el-input__inner {
  color: #000 !important;
}
/* .el-select-dropdown  ul.el-scrollbar__view.el-select-dropdown__list span {
    font-size: 3.6vw;
} */
.meetinfo .questionmeet {
  padding: 0 2vw;
  min-height: 8.8vw;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
}
.meetinfo .questionmeet input {
  /* font-size: 3.6vw; */
}
.meetinfo .questionmeet .el-input__suffix {
  visibility: hidden;
}
.meetinfo .meetrowinput {
  clear: both;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2vw 0;
  font-weight: 400;
  position: relative;
}

.meetinfo .otherrowinput {
  align-items: flex-start;
}
.meetinfo .otherrowinputdesc {
  align-items: flex-start;
  border-bottom: 1px solid #e6e6e6;
  height: 40vw;
}
.topic input {
  text-align: center !important;
}
.meetinfo .meetrowkey {
  min-width: 22vw;
  height: 100%;
  float: left;
  text-align: left;
  font-size: 4vw;
  color: #7e7e7e;
}

.meetinfo .meetrowkey_mark {
  color: #f00;
}
.meetinfo .meetclumnvalue {
  border-bottom: 1px solid #e2e2e2;
  float: left;
  flex: 1;
  position: relative;
  /* max-width:67vw; */
}
.meetinfo .meetclumnvalue .el-select {
  /* max-width:67vw; */
}
.meetinfo .metrowinput_first {
  /* padding-bottom:4vw !important; */
  position: relative;
}
.meetinfo .metrowinput_first .meetclumnvalue {
  /* border:none !important; */
  /* padding-bottom: 1vw; */
}
.meetinfo .metrowinput_first .meet-clunmu-input {
  /* padding-bottom: 1vw; */
}
.meetinfo .inputtopblock_example {
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 3.2vw;
  padding-bottom: 2vw;
  color: #c0c4cf;
}
.meet-clunmu-input {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 2vw 0;
}
.meet-clunmu-input input {
  height: 5.6vw;
  line-height: 5.6vw;
  padding: 0;
  margin: 0;
}
.meetinfo .inputtopblock {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
/* .el-select-dropdown{
  max-width:67vw;
} */
.meetinfo .meetrowtitle {
  height: 12vw;
  line-height: 12vw;
  font-size: 4vw;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
}
.meetinfo input {
  border: none !important;
  color: #000;
  padding: 0;
  font-size: 4vw;
}
.meetinfo .is-disabled input {
  background: none !important;
}
.meetinfo .select {
  position: absolute;
  width: 100%;
  background: #ececec;
  height: 8vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
}
.meetinfo .selectTop {
  position: absolute;
  width: 100%;
  background: #ececec;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
}
.meetinfo .select > i {
  font-size: 8vw;
  color: #81304b;
}
.meetinfo .showDate {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.meetinfo .show_dateBg {
  /* height:954px; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.meetinfo .date_main {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.meetinfo .problem_desc {
  position: relative;
  border: none;
}
.meetinfo .el-textarea {
  margin: 0;
  padding: 0;
  font-size: 4vw;
  flex: 1;
}
.meetinfo .el-textarea__inner:focus {
  border-color: #c0c4cc !important;
}
.meetinfo .el-textarea__inner {
  height: 40vw;
  border: 0 !important;
  padding: 0;
  overflow-y: auto;
  /* font-size: 30px; */
}
.meetinfo .can_use_num {
  /* position: absolute; */
  color: #b7b7b7;
  /* right:1.32vw; */
  /* bottom:.8vw; */
  /* flex:16vw 0 0; */
  font-size: 4vw;
  display: inline-block;
  width: 100%;
  font-weight: 100;
  text-align: right;
}
.meetinfo .file_group {
  display: none;
}
.meetinfo .imgCont {
  height: 24vw;
  display: flex;
  padding-bottom: 4vw;
}
.meetinfo .imgBlock {
  margin-right: 5vw;
  width: 18vw;
  height: 100%;
  border: 1px solid gray;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.meetinfo .imgBlock:last-child {
  margin: none;
}

.meetinfo .cur_img {
  height: 100%;
  width: 100%;
}
.meetinfo .meetrelated {
  height: 17.73vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
}
.meetinfo .meetrelatedname {
  color: #000;
  /* font-size:3.86vw; */
  font-size: 3vw;
  line-height: 6.26vw;
}
.meetinfo .meetrelatedocco {
  color: #666;
  font-size: 3.33vw;
  line-height: 6.26vw;
  font-weight: 400;
}
.meetinfo .meetspaceline {
  display: flex;
}
.meetinfo .meetspaceline span {
  display: inline-block;
  width: 6.67vw;
  height: 1px;
  background: #ccc;
}
.meetinfo .meetshowtips {
  margin-top: 6.66vw;
  font-size: 3.33vw;
  width: 100%;
  text-align: center;
  color: #bbb;
}
.meetinfo .meetoperabtn {
  height: 24.33vw;
  padding: 4.4vw 5.86vw;
}

.meetinfo .meetoperabtn > button {
  width: 100%;
  font-size: 3.73vw;
}
.meetinfo .meetoperabtn > button > span {
  display: inline-block;
}
.meetinfo .meetoperabtn > button span,
.meetinfo .meetoperabtn > button img {
  vertical-align: middle;
}
.meetinfo .meetalterbtn {
  height: 22.26vw;
  width: 100%;
  padding: 6.53vw 0;
  display: flex;
  align-items: center;
}
.meetinfo .meetalterbtn > button {
  flex: 1;
  /* height:9.33vw; */
  width: 100%;
  font-size: 3.73vw;
  /* margin-right: 11.4vw; */
}
.meetinfo .meetalterbtn > button > span {
  display: inline-block;
}
.meetinfo .meetalterbtn > button span,
.meetinfo .meetalterbtn > button img {
  vertical-align: middle;
}
.meetinfo .cancelmeet {
  font-size: 3.73vw;
  color: #666;
  /* width:15.6vw; */
  width: 17.6vw;
}
.meetinfo .meetusericon {
  position: absolute;
  right: 2.64vw;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  color: #e6e6e6;
}
.meetinfo .showtimeblock {
  width: 48vw;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  /* border-bottom:1px solid #ececec */
}
.meetrowinputtips {
  color: #c0c4cf;
  display: inline-block;
  margin-top: 0.5vw;
}

.meetinfo_show_bold_text {
  font-weight: 700;
}
.meetinfo_confirm_customClass {
  width: 90%;
}
.meetinfo_confirm_customClass .el-message-box__message,.meetinfo_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
.meetinfo_button {
  /* width: 24vw; */
  padding: 1vw 4vw;
  border-radius: 2vw;
  color: #fff;
  background: #008f3d;
  text-align: center;
}
.meetrowinput .el-input {
  flex: 1 0 0;
}
.meetrowinput .courseware_title {
  flex: 1 0 0;
  padding: 2vw 0;
}
.meetinfo_remind {
  color: #ff080c;
  font-weight: bold;
  line-height: 1.8;
  padding-left: 2vw;
}
.meetinfo_mate_doctor {
  margin-top: 4vw;
  width: 100%;
  height: 40vw;
}
.meetinfo_mate_doctor_item {
  padding-left: 6vw;
  line-height: 2;
  border-bottom: 1px solid #ccc;
}
.meetinfo_mate_doctor :last-child {
  border-bottom: none !important;
}
.meetinfo_doctorinfo {
  background: #fff;
  margin-top: 4vw;
}
.meetinfo_doctorinfo_title {
  background: #fff;
  min-height: 12vw;
  line-height: 12vw;
  font-size: 4vw;
  font-weight: 400;
}
.meetrowtitle_pd {
  padding: 0 4vw;
}
.biographical_notes .meetinfo_doctorinfo_key {
  min-height: 12vw;
  font-size: 4vw;
  font-weight: 400;
  padding: 2vw 0;
}
.biographical_notes {
  background: #f1f1f1;
}
.biographical_notes .meet_cover {
  width: 40vw;
  height: 40vw;
  background-color: #c9c5c5;
  background: #c9c5c5;
}
</style>